'use client';
import { IBrand } from '@/shared/api/getMainPage/types';
import { LazyImage } from '@/shared/ui/LazyImage';
import { Link } from '@/shared/ui/Link';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { FC } from 'react';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

interface Props {
  className?: string;
  direction?: 'left' | 'right';
  items: IBrand[];
}

export const Marquee: FC<Props> = ({ className, direction, items }) => {
  return (
    <Swiper
      modules={[Autoplay]}
      className={twMergeExt(cvaRoot(), className)}
      slidesPerView={'auto'}
      speed={3000}
      autoplay={{
        reverseDirection: direction === 'right',
        delay: 0,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }}
      loop
    >
      {items.map((i, index) => (
        <SwiperSlide key={index} className={'h-8.3 !w-min'}>
          <Link href={process.env.NEXT_PUBLIC_FRONT_BASE_URL + `/brands/${i.code}/`} prefetch={false}>
            <LazyImage
              wrapClassName={cvaImageWrapper()}
              className={'object-contain grayscale'}
              src={i.preview_picture}
              alt={''}
              fill
            />
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const cvaRoot = cva(['Marquee-cvaRoot', ' [&>.swiper-wrapper]:ease-linear']);

const cvaImageWrapper = cva([
  'Marquee-cvaImageWrapper',
  'relative',
  'w-[11rem] h-[6.13rem] sm:w-[15rem] h-8.3',
  'mx-3.2 sm:mx-4.4',
  'transition-transform duration-300 hocus:scale-110',
]);
