import { VariantProps } from 'class-variance-authority';
import { FC } from 'react';
import { cvaCircle, cvaLoading } from './LoadingSpinnerStyles';
import { twMergeExt } from '@/shared/utils/twMergeExt';

interface Props extends VariantProps<typeof cvaLoading>, VariantProps<typeof cvaCircle> {
  className?: string;
}

const LoadingSpinner: FC<Props> = ({ size, color, className }) => {
  return (
    <div className={twMergeExt(cvaLoading({ size }), className)}>
      <svg className={cvaCircle({ color })} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.9542 8.53851C19.2811 5.39488 24.3929 3.50902 29.7247 3.0894C35.0564 2.66978 40.4004 3.73276 45.1657 6.16081C49.931 8.58886 53.9321 12.2874 56.7266 16.8475C59.521 21.4077 61 26.6517 61 32" />
        <path d="M49.0458 55.4615C44.7189 58.6051 39.6071 60.491 34.2753 60.9106C28.9436 61.3302 23.5996 60.2672 18.8343 57.8392C14.069 55.4111 10.0679 51.7126 7.27344 47.1525C4.47899 42.5923 3.00001 37.3482 3.00001 32" />
      </svg>
    </div>
  );
};

export default LoadingSpinner;
