'use client';
import { IBrand } from '@/shared/api/getMainPage/types';
import { Container } from '@/shared/ui/Container';
import { Link } from '@/shared/ui/Link';
import { Title } from '@/shared/ui/Title';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { Marquee } from '@/widgets/brands-marquee-section/ui/Marquee';
import { cva } from 'class-variance-authority';
import { useInView } from 'framer-motion';
import { FC, useRef } from 'react';
import ArrowRightIcon from '~/icons/arrow-right.svg';

interface Props {
  className?: string;
  items: IBrand[];
}

export const BrandsMarqueeSection: FC<Props> = ({ className, items }) => {
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref, { once: true });

  return (
    <div ref={ref} className={twMergeExt(cvaRoot(), className)}>
      <Title className={cvaHeading()} as={'h2'} trigger={inView}>
        <div>   Более 300</div>
        <div className={'text-cBlack'}>популярных брендов косметики</div>
      </Title>

      <div className={cvaTickers()}>
        <Marquee
          direction={'left'}
          items={[...(items?.map((item) => item) || []), ...(items?.map((item) => item) || [])]}
        />
        <Container className={cvaContainer()} variant={'large'}>
          <div className={cvaSeparator()} />
        </Container>
        <Marquee
          direction={'right'}
          items={[...(items?.map((item) => item) || []), ...(items?.map((item) => item) || [])]}
        />
      </div>

      <div className={'flex justify-center'}>
        <Link
          className={cvaLink()}
          color={'outlinedOnWhite'}
          size={'big'}
          hoverEffect={'highlight'}
          prefetch={false}
          href={process.env.NEXT_PUBLIC_FRONT_BASE_URL + '/brands/'}
        >
          <div className={'flex justify-center items-center gap-[0.45rem] pl-0.9'}>
            Все бренды
            <ArrowRightIcon className={'w-1.6 h-1.6 sm:w-1.8 sm:h-1.8 mt-0.4'} />
          </div>
        </Link>
      </div>
    </div>
  );
};

const cvaRoot = cva(['BrandsMarqueeSection-cvaRoot', 'my-6.4 sm:my-[10.4rem]']);

const cvaHeading = cva([
  'BrandsMarqueeSection-cvaHeading',
  'max-w-[86.8rem]',
  'mx-auto',
  'font-secondary text-h1 text-cMediumGreen text-center',
]);

const cvaTickers = cva(['BrandsMarqueeSection-cvaTickers', 'mt-3.2 sm:mt-7.2']);

const cvaContainer = cva(['BrandsMarqueeSection-cvaContainer', 'my-2.4 sm:my-4.8']);

const cvaSeparator = cva(['BrandsMarqueeSection-cvaSeparator', 'h-0.1', 'bg-cBlack16']);

const cvaLink = cva(['BrandsMarqueeSection-cvaLink', 'mt-3.2 sm:mt-7.2', 'text-bodyLSemibold']);
