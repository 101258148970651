import Typograf from 'typograf';

export const typograf = new Typograf({
  locale: ['ru', 'en-US'],
});

/*
 * https://www.npmjs.com/package/typograf
 *
 * Полный список правил типографа:
 * https://github.com/typograf/typograf/blob/1691fcf2a31a0dadeefbd2a6d2e7b65f89a5c469/docs/RULES_SORTED.ru.md
 * */

// Отключить все правила
typograf.disableRule('*');

// Неразрывный пробел после короткого слова, не более 3 символов
typograf.enableRule('common/nbsp/afterShortWord');
typograf.setSetting('common/nbsp/afterShortWord', 'lengthShortWord', 3);

// Нераз. пробел перед последним коротким словом в предложении
typograf.enableRule('common/nbsp/beforeShortLastWord');

// Нераз. пробел в сокращениях, например, в «т. д.»
typograf.enableRule('ru/nbsp/abbr');

// Расстановка нераз. пробела после «г.», «обл.», «ул.», «пр.», «кв.» и др.
typograf.enableRule('ru/nbsp/addr');

// Нераз. узкий пробел после №
typograf.enableRule('ru/nbsp/afterNumberSign');

// Нераз. пробел между числом и месяцем
typograf.enableRule('ru/nbsp/dayMonth');

// Неразр. пробел между числом и «тыс.», «млн», «млрд» и «трлн»
typograf.enableRule('ru/nbsp/mln');

// Нераз. пробел после OOO, ОАО, ЗАО, НИИ и ПБОЮЛ
typograf.enableRule('ru/nbsp/ooo');

// Нераз. пробел после «стр.», «гл.», «рис.», «илл.»
typograf.enableRule('ru/nbsp/page');

// Нераз. пробел в P. S. и P. P. S.
typograf.enableRule('ru/nbsp/ps');

// Нераз. пробел перед «руб.» и «коп.»
typograf.enableRule('ru/nbsp/rubleKopek');

// Нераз. пробел после сокращений «см.» и «им.»
typograf.enableRule('ru/nbsp/see');

// Нераз. пробел после XXXX г. (2012 г.)
typograf.enableRule('ru/nbsp/year');

// Нераз. пробел перед «ли», «ль», «же», «бы», «б»
typograf.enableRule('ru/nbsp/beforeParticle');

// м2 → м², м3 → м³ и нераз. пробел
typograf.enableRule('ru/nbsp/m');
